<script setup>
import { ref, computed } from 'vue'
import { useMq } from 'vue3-mq'
import { useServicesStore, useExcavationStore, useReconStore } from '@/stores'
import { getFormattedFromToDate } from '@/utils/strings'
import { hasDataPermissions } from '@/utils'
import SAttachmentsImages from '@/components/s-attachments/s-attachments-images.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  object: {
    type: Object,
    required: true
  },
  basicFields: {
    type: Object,
    required: false
  },
  secondaryFields: {
    type: Object,
    required: false
  },
  galleryFields: {
    type: Object,
    required: false
  },
  isShowTitle: {
    type: Boolean,
    default: false
  },
  singleColumn: {
    type: Boolean,
    default: false
  },
  images: {
    type: Array,
    default: null
  }
})

const emits = defineEmits(['edit-modal'])

const mq = useMq()

const servicesStore = useServicesStore()
const excavationStore = useExcavationStore()
const reconStore = useReconStore()

const showAdditional = ref(false)

const editExcavation = () => {
  if (disabled.value) return
  emits('edit-modal')
}

const disabled = computed(() => {
  const geologist = props.item?.geologist

  return !hasDataPermissions(geologist)
})

const services = computed(() => {
  if (servicesStore) {
    const { excav, bore_machines, users, bore_masters, processes } = servicesStore
    const types = processes?.types
    return {
      ...excav,
      bore_machines,
      bore_masters,
      users,
      types
    }
  }

  return null
})

const primaryFields = computed(() => {
  if (props.basicFields?.mobileFields) {
    return mq.current !== 'sm' ? props.basicFields.desktopFields : props.basicFields.mobileFields
  }

  return props.basicFields?.desktopFields
})

const secondaryFields = computed(() => {
  if (!props.secondaryFields) return

  if (props.secondaryFields?.mobileFields) {
    return mq.current !== 'sm'
      ? props.secondaryFields.desktopFields
      : props.secondaryFields?.mobileFields
  }

  return props.secondaryFields.desktopFields
})

const getValue = (field) => {
  let value

  switch (field.type) {
    case 'select':
      return getFromService(field)
    case 'date':
      return getDate()
    case 'coordinates':
      return getCoordinates()
    case 'coordinates-plan':
      return getCoordinates('plan')
    case 'coordinates-fact':
      return getCoordinates('fact')
    case 'depth':
      return getDepth()
    case 'diam':
      return getDiam()
    case 'casing_meters':
      return `${props.item[field.title] || 0} м`
    case 'string':
      value = props.item[field.title] || ''
      if (props.item[field.second]) {
        value += ` ${props.item[field.second]}`
      }
      return value || '-'
    default:
      return '-'
  }
}

const getFromService = (field) => {
  const isId = (id) => !!id || Number.isInteger(parseInt(id))

  if (!isId(props.item?.[field.title])) return '-'
  let title = ''
  const service = services?.value[field.select]
  if (Array.isArray(props.item[field.title])) {
    const arrayOfIDS = props.item[field.title]
    arrayOfIDS.forEach((id, idx) => {
      if (idx === 0) {
        title += service?.find((e) => e.id === id)?.title || ''
      } else {
        title += `, ${service?.find((e) => e.id === id)?.title || ''}`
      }
    })
  } else {
    const itemId = props.item[field.title]
    title = service?.find((e) => e.id === itemId)?.title || ''
  }

  if (props.item[field.commentField]) {
    title += ` ${props.item[field.commentField]}`
  }

  if (field.title === 'geologist' && props.item?.server_id) {
    const geologist = servicesStore?.object_users?.find((user) => user.id === props.item.geologist)
    if (geologist && !geologist.is_active) {
      return `${title.trim()} (удален)`
    }
  }

  return title.trim() || '-'
}

const getDate = () => {
  if (props.item.type === 101) {
    return getFormattedFromToDate(props.item?.date_from, props.item?.date_to, props.item.type)
  }
  return getFormattedFromToDate(props.item?.date_from)
}

const getCoordinates = (type) => {
  const { lon, lat, lon_plan, lat_plan } = props.item

  if (!type) {
    if (lon && lat) {
      return `${Number(lat).toFixed(5)}, ${Number(lon).toFixed(5)}`
    } else {
      return `${Number(lat_plan).toFixed(5)}, ${Number(lon_plan).toFixed(5)}`
    }
  }

  if (type === 'fact') {
    return `${lat ? Number(lat).toFixed(5) : '-'} / ${lon ? Number(lon).toFixed(5) : '-'}`
  } else {
    return `${Number(lat_plan).toFixed(5)}, ${Number(lon_plan).toFixed(5)}`
  }
}
const getDepth = () => {
  const { h_plan } = props.item
  const h = props.item?.h || 0

  return `${h} / ${h_plan} м`
}

const getDiam = () => {
  const diam1 = props.item?.diam1 || '-'
  const diam2 = props.item?.diam2 || '-'

  return `${diam1} / ${diam2} мм`
}

const excavImages = computed(() => {
  return excavationStore.excavationImages
})

const getImagesFromStore = (field) => {
  return reconStore[field]
}
</script>

<template>
  <div class="data-card" :class="{ 'single-column': singleColumn }" @click="editExcavation">
    <div v-if="isShowTitle" class="data-card-block stretch">
      <div class="data-card__item">
        <s-text>Объект</s-text>
        <s-title type="small" class="data-card__item-ellipsis">
          {{ object.title_short }}
        </s-title>
      </div>
    </div>
    <div class="data-card-block" v-for="fields in primaryFields" :key="fields.id">
      <div
        v-for="(field, index) in fields.fields"
        :key="field.id"
        :class="['data-card__item', `area-${index + 1}`, { stretch: field.stretch }]"
      >
        <s-text>{{ field.label }}</s-text>
        <s-title type="small">{{ getValue(field) }}</s-title>
        <s-text
          v-if="field.type === 'coordinates'"
          class="data-card__coordinates-system"
          small
          color="var(--caption)"
        >
          (WGS 84)
        </s-text>
      </div>
    </div>
    <template v-if="galleryFields">
      <div class="data-card__images" v-for="item in galleryFields" :key="item.id">
        <template v-if="getImagesFromStore(item.storeField).length">
          <s-text>{{ item.title }}</s-text>
          <div class="data-card__images-wrapper">
            <s-attachments-images
              :items="getImagesFromStore(item.storeField)"
              mode="multi"
              thumbnails
            />
          </div>
        </template>
      </div>
    </template>
    <template v-if="showAdditional">
      <div class="data-card-block stretch" v-for="fields in secondaryFields" :key="fields.id">
        <div v-for="field in fields.fields" :key="field.id" class="data-card__item large">
          <s-text>{{ field.label }}</s-text>
          <s-title type="small">{{ getValue(field) }}</s-title>
        </div>
      </div>
      <div v-if="excavImages.length" class="data-card__images">
        <s-text>Фотографии</s-text>
        <div class="data-card__images-wrapper">
          <s-attachments-images :items="excavImages" mode="multi" thumbnails />
        </div>
      </div>
    </template>
    <div
      v-if="secondaryFields"
      class="data-card__toggler"
      @click.stop="showAdditional = !showAdditional"
    >
      <s-button :icon="showAdditional ? 'chevron-up' : 'chevron-down'" simple />
      <s-text>
        {{ showAdditional ? 'Скрыть все' : 'Показать все' }}
      </s-text>
    </div>
  </div>
</template>

<style lang="scss">
.data-card {
  display: grid;
  background-color: var(--bg);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  cursor: pointer;

  &-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.75rem 0;
    padding: 0.75rem 1rem;

    @include tablets {
      grid-template-columns: 1fr;
      grid-template-areas:
        'area-1'
        'area-3'
        'area-2'
        'area-4';
    }

    &:not(:first-child) {
      border-top: 1px solid var(--main-bg);
    }

    &.stretch {
      grid-template-columns: 1fr;
    }
  }
  &.single-column {
    & .data-card-block {
      grid-template-columns: 1fr;
    }

    & .data-card__item {
      grid-template-columns: 128px 1fr;
    }
  }

  &__images {
    border-top: 1px solid var(--main-bg);
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:empty {
      display: none;
    }

    &-wrapper {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      & .attachments-gallery__item {
        border: 1px solid var(--main-bg);
      }
    }
  }

  &__item {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-template-columns: auto auto auto;
    gap: 8px;

    &.stretch {
      grid-column: 1 / -1;
    }

    @include tablets {
      &.area-1 {
        grid-area: area-1;
      }
      &.area-2 {
        grid-area: area-2;
      }
      &.area-3 {
        grid-area: area-3;
      }
      &.area-4 {
        grid-area: area-4;
      }
    }

    &-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > * {
      &:first-child {
        min-width: 112px;
      }
    }

    &.large {
      > * {
        &:first-child {
          width: 160px;
        }
      }
    }
  }

  &__toggler {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.75rem;
    gap: 8px;

    .s-button__icon {
      height: 0.75rem !important;
    }

    .s-button {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

@include phones {
  .data-card {
    &-block {
      grid-template-columns: 1fr;
    }

    &__toggler {
      padding: 0.25rem;
      border-top: 1px solid var(--main-bg);
    }
  }
}
</style>
